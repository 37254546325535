/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { ReduxState } from '@/types';
import { sessionStorage } from '@/utils/sessionStorage';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { setCookie } from '@/utils/cookie';
import { getDecodedCookies } from '@/utils/cookie';
import { useFeatureFlag } from '@/features/globalConfig';
import { SearchBoxContext } from '@/components/SearchBoxContext';

export function useShouldShowSignInAlert() {
  const rawPopupShowCount = useFeatureFlag('POPUP_PAGENUMBER_SHOW_COUNT');
  const popupShowCount = rawPopupShowCount ? parseInt(rawPopupShowCount, 10) : 0;
  const { searchBoxOpen } = useContext(SearchBoxContext);

  const userAuthenticated = useSelector<ReduxState>((state) => state.appData.userAuthenticated);
  const router = useRouter();
  const pageCount = Number(sessionStorage.getItem('pageCount') ?? 1);
  const isClient = typeof document !== 'undefined';

  const alertCookie = isClient ? getDecodedCookies(['alert_timer']) : null;
  const cookie = alertCookie?.get('alert_timer');
  const [show, setShow] = useState(() => {
    if (__IS_SERVER__) {
      return false;
    }
    if (
      (sessionStorage.getItem('signInPopupShown') !== 'true' ||
        sessionStorage.getItem('signInPopupDisabled') !== 'true') &&
      cookie == undefined
    ) {
      return true;
    }
  });

  const hideForSession = () => {
    sessionStorage.setItem('signInPopupShown', 'true');
    const date = new Date();
    setCookie('alert_timer', 'expires in 10 minutes', {
      expires: new Date(date.setTime(date.getTime() + 10 * 60 * 1000)),
    });
    setShow(false);
  };
  return {
    hideForSession,
    shouldShowAlert:
      !userAuthenticated &&
      show &&
      router.route !== '/cart' &&
      router.route !== '/checkout' &&
      pageCount >= popupShowCount &&
      sessionStorage.getItem('DropDownRendered') !== 'true' &&
      !searchBoxOpen,
  };
}
