/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import { useEffect } from 'react';
import { Popper, PopperHeader, PopperBody } from '@/components/AZCustomComponent/Popper/Popper';
import { SmartLink } from '@/utils/smartLink';
import { setCookie } from '@/utils/cookie';
import { Text } from '@az/starc-ui';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import styles from './SignInAlertDesktop.module.scss';
import { useRouter } from 'next/router';
import { useShouldShowSignInAlert } from './useShouldShowSignInAlert';
import { Label } from '@/features/i18n';
import { eventConstants } from '@/constants/event';
import { sessionStorage } from '@/utils/sessionStorage';
import { isSafari } from '@/utils/common';
import { useDeviceDetector } from '@/hooks/useDeviceDetector';

type Props = {
  anchorRef: HTMLElement | null;
  drawerOpen: boolean;
};

export function SignInAlertDesktop({ anchorRef, drawerOpen }: Props) {
  const router = useRouter();
  const isTabletOrDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const { shouldShowAlert, hideForSession } = useShouldShowSignInAlert();
  const { platform } = useDeviceDetector();

  const isWindows = platform === 'windows';

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (shouldShowAlert && sessionStorage.getItem('signInPopupShown') !== 'true') {
        sessionStorage.setItem('signInPopupDisabled', 'true');
      }
    }, 2000);
    clearTimeout(timeoutId);
  }, [shouldShowAlert]);

  useEffect(() => {
    // close the alert if the user presses the escape key
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === eventConstants.escKeyType) {
        hideForSession();
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [hideForSession]);
  const showAlert = shouldShowAlert && isTabletOrDesktop && anchorRef && !drawerOpen;

  if (!showAlert) {
    return null;
  }
  const handleCreate = (event: React.SyntheticEvent) => {
    event.preventDefault();
    hideForSession();
    void router.push('/signin');
    setCookie('loginInteractionMethod', 'Login Alert');
  };

  return (
    showAlert && (
      <Popper
        elevation={4}
        isOpen={!__IS_SERVER__}
        anchorEl={anchorRef}
        placement="bottom-end"
        classes={{
          arrow: styles.tooltipArrow,
          popper: styles.signInAlertPopper,
          paper: styles.signInPaper,
        }}
        disablePortal
        ariaLabelledByID="header"
        role="dialog"
        hideForBots
      >
        <PopperHeader
          handleClose={hideForSession}
          typographyComponent="div"
          classes={{
            heading: cx(styles.alertHeader, { [styles.safariHeader]: isSafari() }),
          }}
        >
          <Label label="sign_in_alert_header" />
        </PopperHeader>
        <PopperBody>
          <Text className={styles.alertBody}>
            <Label label="label_rewards_waiting" />{' '}
            <span className={cx(styles.links, { [styles.boldLink]: isWindows })}>
              <SmartLink to="/signin" onClick={handleCreate} rel="nofollow">
                <Label label="label_SignInorCreateAccount" />
              </SmartLink>
            </span>
          </Text>
        </PopperBody>
      </Popper>
    )
  );
}
