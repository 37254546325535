/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect, useState } from 'react';

type PlatformTypes = 'android' | 'ios' | 'windows' | 'macos' | 'other';

export const useDeviceDetector = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [platform, setPlatform] = useState<PlatformTypes>('other');

  useEffect(() => {
    const hasTouchScreen = navigator.maxTouchPoints > 0;
    setIsMobile(hasTouchScreen);
    if (hasTouchScreen && /iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      setPlatform('ios');
    } else if (hasTouchScreen && /Android/i.test(navigator.userAgent)) {
      setPlatform('android');
    } else if (/Mac/i.test(navigator.userAgent)) {
      setPlatform('macos');
    } else if (/Windows/i.test(navigator.userAgent)) {
      setPlatform('windows');
    }
  }, []);

  return { isMobile, platform };
};
